import { createSlice } from '@reduxjs/toolkit';
import {
  fetchBorder,
  fetchMobility,
  buildMatrix,
  calculateScores
} from './helper-diagram';

export const diagramSlice = createSlice({
  name: 'diagram',
  initialState: {
    borders: {"type":"featureCollection", "features": []},
    scores: [],
    flows: [],
    matrix: [],
    index: null,
    mobility: [[]],
    diagramSettings: {
      url: 'https://api.tiles.mapbox.com/v4/mapbox.dark/{z}/{x}/{y}.png?' +
        'access_token=' +
        'pk.eyJ1IjoiYXlhbmV6IiwiYSI6ImNqNHloOXAweTFveWwzM3A4M3FkOWUzM2UifQ.' +
        'GfClkT4QxlFDC_xiI37x3Q',
      attribution: '&copy; <a href=\'http://osm.org/copyright\'>OpenStreetMap</a>' +
        ' contributors ',
      lat: 8.3,
      lng: -12.0,
      zoom: 8,
      docker: false,
      value: 3,
      didUpdate: false,
      loading: false,
      onHover: false,
    }
  },
  reducers: {
    setDiagram: (state, action) => {
      state.index =  action.payload.index
      state.matrix =  action.payload.matrix
      state.mobility =  action.payload.mobility
      state.scores =  action.payload.scores
      state.borders = action.payload.borders
      state.flows = action.payload.flows
    },
  },
});

// export const {setBorders, setScores, setIndex, setMatrix, setMobility, setDiagram} = diagramSlice.actions;
export const { setDiagram } = diagramSlice.actions;
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const prepareDiagram = (borders, date, index, matrix, mobility, direction, scale, provider) => dispatch => {
  Promise.all([
    fetchBorder(borders),
    fetchMobility(date, mobility, provider)]
  ).then(values => {
    if (values.error) {
      // No data for this date by this provider
      return dispatch(setDiagram({}));
    }
    let [borders, mobility] = values
    let matrix = buildMatrix(borders, mobility, direction)
    let [scores, flows] = calculateScores(borders, matrix, index, scale)
    dispatch(setDiagram(
      {
        index,
        matrix,
        mobility,
        borders,
        scores,
        flows
      }
    ));
  })
};


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectDiagramSettings = state => state.diagram.diagramSettings;
export const selectBorders = state => state.diagram.borders;
export const selectMatrix = state => state.diagram.matrix;
export const selectMobility = state => state.diagram.mobility;
export const selectIndex = state => state.diagram.index;
export const selectScores = state => state.diagram.scores;
export const selectFlows = state => state.diagram.flows;
export default diagramSlice.reducer;

import axios from 'axios';
import * as topojson from 'topojson-client';
const config = require('../../config')
const admin = config.admin.name
export function fetchBorder (borders) {
  return new Promise((resolve, reject) => {
    if (borders) {
      return resolve(borders)
    }
    let url = process.env.REACT_APP_API_HOST + '/borders'
    axios.get(url, {headers: {"Authorization": window.jwt}})
    .catch(err => {
      console.log(err)
      reject(err)
    })
    .then(response => {
      let topo = response.data
      let geojson = topojson.feature(topo, topo.objects.district)
      return resolve(geojson)
    });
  })
}

export function fetchMobility (date, mobility, provider) {
  if (mobility) {
    console.log(provider, mobility.provider)
    if (date[0].date === parseInt(mobility.date) &&
    provider === mobility.provider) {
      console.log("nothing's changed")
      return mobility
    }
  }

  if (date) {
    if (typeof date.getMonth === 'function') {
      // Map has no polygons yet
      return
    }

    return new Promise((resolve, reject) => {
      let url = process.env.REACT_APP_API_HOST +
      '/mobility/date/' +
      provider +
      '/' + date[0].date
      axios.get(url, {headers: {"Authorization": window.jwt}})
      .catch(err => {
        console.log(err)
        reject(err)
      })
      .then(response => {
        if (!response) return
        if (response.data.error) {
          return resolve(false)
        }
        let mobility = response.data
        return resolve(mobility)
      });
    })
  } else {
    // No arguments received.
    // Don't do anything
    console.log('do nothing')
    return
  }

}

export function buildMatrix(geojson, mobility, direction) {
  let size = geojson.features.length
  let matrix = Array(size).fill(0).map(()=>Array(size).fill(0))
  if (mobility) {
    mobility = mobility.mobility
    Object.keys(mobility).forEach(toFrom => {
      let [source, target] = toFrom.split('-').map(e => {return parseInt(e)})
      matrix[source][target] = mobility[toFrom]
      if (direction === 'ingress') {
        let val = mobility[[target, source].join('-')] ? mobility[[target, source].join('-')] : 0
        matrix[source][target] = val

      }
    })
  }
  return matrix
}

/**
 * Returns array of scores between 0 and 1
 * @param  {Array} ary
 * @return {Boolean} score
 */
export function get_scores(ary, index, scale) {
  // Return ary as is if all elements are 0
  if (ary.filter(e => { return e > 0}).length === 0) {
    return ary
  }

  if (ary.length < 1) {
    return []
  }
  let max = ary.reduce(function(a, b) {
     return Math.max(a, b);
   });
   // Polygon has been clicked, make second largest number the max
   if (index) {
     let arry_proxy = [...ary]
     max = arry_proxy.sort(function(a, b) { return b - a; })[1];
   }

  let min = ary.reduce(function(a, b) {
    return Math.min(a, b);
  });

  return ary.map(function(e) {
    return score(min, max, parseInt(e), index, scale)
  })
}
/**
 * Returns score between 0 and 1
 * @param  {Integer} min
 * @param  {Integer} max
 * @param  {Integer} number
 * @return {boolean} score
 */
function score(min, max, number, index, scale) {
  let score_min = min
  let score_max = max
  let low_bound = 0
  let top_bound = 1
  if (scale.match(/linear/)) {
    return (number - score_min) / (score_max - score_min) *
    (top_bound - low_bound) + low_bound
  } else {
    return Math.log(number+1)/Math.log(score_max+1)
  }
}

function showHitList(vector, geojson) {
  let hitList = vector.reduce((a, e, i) => {
    a[i] = [e, i]
    return a
  }, [])

  return hitList.sort(function(a, b) {
    return b[0] - a[0];
  }).filter(e => {
    return e[0] > 0
  }).map(e => {
    return [e[0], geojson.features[e[1]].properties[admin]]
  })
}

export function calculateScores(geojson, matrix, index, scale) {
  // No index, so use diagonal
  let vector = geojson.features.map((f, i) => {
    return matrix[i][i]
  })
  if (index) {
    vector = matrix[index]
  }
  let flows = showHitList(vector, geojson)
  let scores = get_scores(vector, index, scale)
  scores = geojson.features.map((f, i) => {
    return scores[i]
  })
  return [scores, flows, index]
}

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  Auth
} from 'aws-amplify';
import {checkIsAuthenticated} from '../../helpers/auth'

import {
  prepareProviders,
  prepareProvider,
  selectProvider,
  prepareDirection,
  selectDirection,
  prepareScale,
  selectScale,
} from './settingsSlice';

import {
  prepareDiagram,
  selectBorders,
  selectIndex,
  selectMatrix,
  selectMobility,
} from '../diagram/diagramSlice';
import {
  selectDate,
} from '../calendar/calendarSlice'
let initializedDate = require('../../config').date
initializedDate = new Date(initializedDate.join(','))
const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: '80px',
    left: '20px'
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  }
});

export function Settings(props) {
  const borders = useSelector(selectBorders);
  const index = useSelector(selectIndex);
  const matrix = useSelector(selectMatrix);
  const mobility = useSelector(selectMobility);
  const direction = useSelector(selectDirection);
  const scale = useSelector(selectScale);
  const provider = useSelector(selectProvider);
  const date = useSelector(selectDate);
  const dispatch = useDispatch();
  const classes = useStyles();
  // const [value, setValue] = React.useState('africell');
  useEffect(() => {
    checkIsAuthenticated().then(() => {
      dispatch(prepareProviders())
    })
  }, []);
  const handleChange = (event) => {
      dispatch(prepareProvider(event.target.value))
      let proxy = event.target.value
      if (date != initializedDate) {
          dispatch(prepareDiagram(borders, date, index, matrix, mobility, direction, scale, proxy))
      }

    // setValue(event.target.value);
  };

  const handleChangeDirection = (event) => {
    let proxy = (direction === 'egress') ? 'ingress' : 'egress'
    dispatch(prepareDirection(proxy))
    if (mobility) {
      dispatch(prepareDiagram(borders, date, index, matrix, mobility, proxy, scale, provider))
    }
  };

  const handleChangeScale = (event) => {
    let proxy = (scale === 'linear') ? 'logarithmic' : 'linear'
    dispatch(prepareScale(proxy))
    dispatch(prepareDiagram(borders, date, index, matrix, mobility, direction, proxy, provider))
  };

  return (
    <div>

      <Card className={classes.root} style={{top: props.distance, bottom: props.distanceBottom}} variant="outlined">
        <CardContent>
          <Grid container spacing={0}>
              <Grid item xs={6}>
                <FormControl component="fieldset">
                  <FormLabel component="legend"> <strong>MNO</strong></FormLabel>
                  <RadioGroup aria-label="mno" defaultValue="africell" name="mno" value={provider} onClick={handleChange}>
                    <FormControlLabel value="africell" control={<Radio />} label="Africell" />
                    <FormControlLabel value="orange" control={<Radio />} label="Orange" />
                  </RadioGroup>
                </ FormControl>
              </ Grid>
              <Grid item xs={6}>
                <br/>
                <Button variant="outlined" size="small" color="primary" value={direction} className={classes.margin} onClick={handleChangeDirection}>
                  {direction}
                </Button>
                <br />
                <br />
                <Button variant="outlined" size="small" color="primary" value={scale} className={classes.margin} onClick={handleChangeScale}>
                  {scale}
                </Button>
            </ Grid>
          </ Grid>
        </CardContent>
      </Card>
</div>

  );
}

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import Amplify from 'aws-amplify';

Amplify.configure({
  Auth: {
    identityPoolId: 'eu-west-1:f2c154b0-18e8-4cfa-844a-7e8399b74fdf',
    userPoolId: 'eu-west-1_mGxHyXajm',
    region: 'eu-west-1',
    userPoolWebClientId: '1lii5688jsg7k2vvrs2s9hqjph',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

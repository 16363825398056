import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { deserializeDates, serializeDates} from '../../utils'
const initializationDate = require('../../config').date
const dates = [[new Date(initializationDate), 37032]]
const date = new Date(initializationDate).toString()
export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    dates: deserializeDates(dates),
    date: date,
  },
  reducers: {
    setDates: (state, action) => {
      state.dates = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
  },
});

export const {setDates, setDate} = calendarSlice.actions;

function formatDates(file_names) {
  let dates = file_names.map(f => {
    let [date, sum] = f.split('-')
    let y = date.substr(0,4)
    let m = parseInt(date.substr(4,2))
    let d = date.substr(6,2)
    return [new Date(y,m-1,d), parseInt(sum)]
  })
  return dates
}

function fetchDates (provider) {
  return new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_API_HOST + '/mobility/provider/' + provider
    axios.get(url, {headers: {"Authorization": window.jwt}})
    .catch(err => {
      console.log(err)
      reject(err)
    })
    .then(response => {
      let dates = formatDates(response.data)
      return resolve(dates)
    });
  })
}

// export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const prepareDates = (provider) => dispatch => {
  fetchDates(provider).then(dates => {
    let serialisedDates = serializeDates(dates)
    dispatch(setDates(serialisedDates));
  })
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = state => state.counter.value;
export const selectDates = state => state.calendar.dates;
export const selectDate = state => state.calendar.date;
export default calendarSlice.reducer;

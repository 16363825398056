import React from 'react';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  autheticatorWrapper: {
    width: '100%',
    'text-align': 'center'
  }
}));

export default function Authenticator() {
  const classes = useStyles();
  return (
    <div className={classes.autheticatorWrapper}>
      <AmplifyAuthenticator />
    </div>
  )
}
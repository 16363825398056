import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  rootDSTI: {
    position:'fixed',
    [theme.breakpoints.down('sm')]: {
          width: '200px',
          bottom: '-4px',
          left: '50px'
        },
        [theme.breakpoints.up('md')]: {
          // width: '200px',
          // bottom: '-4px',
          width: '200px',
          bottom: '-4px',
          left: '50px'

        },
        [theme.breakpoints.up('lg')]: {
          // width: '400px',
          // bottom: '15px',
          width: '200px',
          bottom: '-4px',
          left: '50px'

        },
  },
  rootUnicef: {
    position:'fixed',
    [theme.breakpoints.down('sm')]: {
          width: '100px',
          bottom: '5px',
        },
        [theme.breakpoints.up('md')]: {
          // width: '100px',
          // bottom: '5px',
          width: '100px',
          bottom: '5px',
        },
        [theme.breakpoints.up('lg')]: {
          // width: '150px',
          // bottom: '40px',
          width: '100px',
          bottom: '5px',
        },
  },

}));
export function Logo() {
  const classes = useStyles();
  return (
    <div>

    <Grid container spacing={2}>
        <Grid item xs={3}>
          <img className={classes.rootDSTI} style= {{
            right: '350px',
          }}
          alt="dsti logo"
          src='/dsti.png' />
          </Grid>
          <Grid item xs={3}>
            <img className={classes.rootUnicef} style= {{
              right: '50px',
            }}
            alt="unicef logo"
            src='/unicef_logo.png' />
          </ Grid>
      </ Grid>
    </div>
  );
}

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Chart
} from 'react-google-charts';
import {
  selectDates,
  setDate
} from './calendarSlice';
import {
  selectProvider,
  selectDirection,
  selectScale
} from '../settings/settingsSlice';
import {
  prepareDiagram,
  selectBorders,
  selectIndex,
  selectMatrix,
  selectMobility,
} from '../diagram/diagramSlice';
import { deserializeDates } from '../../utils'

export function Calendar() {
  const dates = deserializeDates(useSelector(selectDates));
  const borders = useSelector(selectBorders);
  const index = useSelector(selectIndex);
  const dispatch = useDispatch();
  const matrix = useSelector(selectMatrix);
  const mobility = useSelector(selectMobility);
  const direction = useSelector(selectDirection);
  const scale = useSelector(selectScale);
  const provider = useSelector(selectProvider);
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
  }, []);

  const onChange = (Chart) => {
    let date = Chart.chartWrapper.getChart().getSelection()
    dispatch(prepareDiagram(borders, date, index, matrix, mobility, direction, scale, provider))
    // Have to set date because I'm fetching the geojson
    // on polygon click.
    dispatch(setDate(date));
  }
  const chartEvents = [{
      eventName: 'select',
      callback: onChange.bind(this) //(e) => {console.log(e)}
    }]
  const columns = [{
    type: 'date',
    id: 'Date'
  }, {
    type: 'number',
    id: 'Mobility'
  }]

  return (
    <div className='calendar-component'>
      <Chart
        chartEvents= {chartEvents}
        chartType="Calendar"
        columns={columns}
        rows={dates}
        options={{
          chartPackages: ['calendar'],
        }}
        height="225px"
        width="900px"
      />
    </div>
  )
}

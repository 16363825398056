import {
  Auth
} from 'aws-amplify';


export function checkIsAuthenticated() {

  return new Promise(resolve => {
    Auth.currentAuthenticatedUser().then(user => {
      window.jwt = user.signInUserSession.accessToken.jwtToken
      return resolve()
    })
  })
}

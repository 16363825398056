import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  prepareDiagram,
  selectBorders,
  selectMatrix,
  selectMobility,
  selectScores,
  selectIndex,
  selectDiagramSettings,
} from './diagramSlice';

import {
  selectProvider,
  selectDirection,
  selectScale
} from '../settings/settingsSlice';

import {
  selectDate
} from '../calendar/calendarSlice';
import { serializeDate } from '../../utils'
import {checkIsAuthenticated} from '../../helpers/auth'
import {
  GeoJSON,
  Map,
  ZoomControl,
  TileLayer,
} from 'react-leaflet'

import {
 onEachAdminFeature,
  adminStyle
} from './helper-diagram-behavior';

const _ = require('lodash');

export function Diagram() {
  const settings = useSelector(selectDiagramSettings);
  const borders = useSelector(selectBorders);
  const matrix = useSelector(selectMatrix);
  const mobility = useSelector(selectMobility);
  const direction = useSelector(selectDirection);
  const scale = useSelector(selectScale);
  const provider = useSelector(selectProvider);
  const scores = useSelector(selectScores);
  const index = useSelector(selectIndex);
  const date = serializeDate(useSelector(selectDate));
  const dispatch = useDispatch();
  const position = [settings.lat, settings.lng]

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    checkIsAuthenticated().then(() => {
      dispatch(prepareDiagram())
    })
  }, []);

  return (
    <div>
      <Map center={position}
        zoom={settings.zoom}
        zoomControl={false}
        >
        <ZoomControl position='bottomright' />
        <TileLayer
          url={settings.url}
          attribution={settings.attribution}
        />

        <GeoJSON
            key={_.uniqueId()}
            data={borders}
            onEachFeature={onEachAdminFeature(
              borders,
              date,
              dispatch,
              index,
              matrix,
              mobility,
              direction,
              scale,
              provider
         )}
         style={adminStyle(index, scores)}
       />
      </Map>
    </div>
  )
}

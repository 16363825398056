import {
  prepareDiagram,
} from './diagramSlice';
const config = require('../../config')
const admin = config.admin.name
/**
 * onEachCountryFeature
 *
 * @param {object} myMapObj
 * @param {number} sliderVal value
 * @param {object} availableCountryPaths
 * @return {function} function
 */
export function onEachAdminFeature(borders, date, dispatch, index, matrix, mobility, direction, scale, provider) {
  return (feature, layer) => {
    layer.on({
      'mouseover': () => {
        layer.bindTooltip(feature.properties[admin]);
      },
      'click': e => {
        if (mobility) {
          let newIndex = e.target.feature.properties.index
          index = index === newIndex ? null : newIndex
          dispatch(prepareDiagram(borders, date, index, matrix, mobility, direction, scale, provider))
        }

      }
    });
  }
}
/**
 * countryStyle - Specifies the style for the geojson
 * @param  {type} props description
 * @return {object} style
 */
export function adminStyle(index, scores) {
  return function(geoJsonFeature) {
    let color = (index === geoJsonFeature.properties.index) ? 'blue' : 'red'
    let fillOpacity = scores ? scores[geoJsonFeature.properties.index] : 0

    const displayCountry = {
      fill: true,
      color: 'yellow',
      weight: '0.3',
      fillColor: color,
      // fillOpacity: geoJsonFeature.properties.score
      fillOpacity
    }
    return displayCountry
  }
}

import { configureStore } from '@reduxjs/toolkit'
import diagramReducer from '../features/diagram/diagramSlice';
import calendarReducer from '../features/calendar/calendarSlice';
import settingsReducer from '../features/settings/settingsSlice';

export default configureStore({
  reducer: {
    diagram: diagramReducer,
    calendar: calendarReducer,
    settings: settingsReducer
  },
});

import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { Intro } from '../../features/intro/Intro';
import { Admin } from '../../features/admin/Admin';
import {
  selectFlows
} from '../diagram/diagramSlice';
import {
  selectDate
} from '../calendar/calendarSlice';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '215px',
    left: '20px',
    top: '250px',
    backgroundColor: theme.palette.background.paper,
    position: 'fixed',
    overflow: 'auto',
    color: theme.palette.text.secondary,
    height:'275px'
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

export  function Legend() {
  const classes = useStyles();
  const flows = useSelector(selectFlows);
  let date = useSelector(selectDate);
  if (date[0]) {

    date = String(new Date(date[0].date)).split(/00:00:00/)[0]
  }
  if (flows.length > 0) {
    return (
      <div>
        <br/>
        <List className={classes.root} subheader={<li />}>
          {flows.map((row, i) => (
            <li key={`section-${i}`} className={classes.listSection}>
              <Admin row={row} nth={i}/>
            </li>
          ))}
        </List>
        <Intro text={date} />
      </div>
    );
  } else {
    return(
      <div>
      </div>
    )
  }

}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
}));

export function Admin(props) {
  const classes = useStyles();
  const lookUp = {
    1: '#B0B0B0',
    2: 'white'
  }
  let key = props.nth % 2 == 0 ? 1 : 2
  function numberWithCommas(x) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={6} style={{backgroundColor: lookUp[key]}}>
          <div className={classes.paper}>{props.row[1]}</div>
        </Grid>
        <Grid item xs={6} style={{backgroundColor: lookUp[key]}}>
          <div className={classes.paper}>{numberWithCommas(props.row[0])}</div>
        </Grid>
      </Grid>
    </div>
  );
}

export function serializeDates(dates) {
  return dates.map(date => {
    return [date[0].toISOString(), date[1]]
  })
}

export function serializeDate(date) {
  if (Object.prototype.toString.call(date) === "[object Date]" || date instanceof Date) {
    return date.toISOString()
  }
  return date
}

export function deserializeDates(dates) {
  return dates.map(date => {
    return [new Date(date[0]), date[1]]
  })
}

export function deserializeDate(date) {
  if (Object.prototype.toString.call(date) === "[object Date]" || date instanceof Date) {
    return date.toISOString()
  }
  return date
}

export default {deserializeDates, deserializeDate, serializeDates, serializeDate}
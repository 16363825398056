import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  prepareDates,
} from '../calendar/calendarSlice';
const providers = []
const provider = null
const direction = 'egress'
const scale = 'linear'
export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    providers,
    provider,
    direction,
    scale
  },
  reducers: {
    setProviders: (state, action) => {
      state.providers =  action.payload;
      state.provider =  action.payload[0]
    },
    setProvider: (state, action) => {
      state.provider =  action.payload;
    },
    setDirection: (state, action) => {
      state.direction =  action.payload;
    },
    setScale: (state, action) => {
      state.scale =  action.payload;
    },
  },
});

export const {setProviders, setProvider, setDirection, setScale} = settingsSlice.actions;

function fetchProviders () {
  return new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_API_HOST + '/mobility/providers'
    axios.get(url, {headers: {"Authorization": window.jwt}})
    .catch(err => {
      alert(JSON.stringify(err))
      reject(err)
    })
    .then(response => {
      return resolve(response.data)
    });
  })
}

// export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const prepareProviders = () => dispatch => {
  fetchProviders().then(providers => {
    dispatch(setProviders(providers));
    dispatch(prepareDates(providers[0]));
  })
};
export const prepareProvider = (value) => dispatch => {
    dispatch(prepareDates(value));
    dispatch(setProvider(value));
};

export const prepareDirection = (value) => dispatch => {
    dispatch(setDirection(value));
};

export const prepareScale = (value) => dispatch => {
    dispatch(setScale(value));
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = state => state.counter.value;
export const selectProvider = state => state.settings.provider;
export const selectProviders = state => state.settings.providers;
export const selectDirection = state => state.settings.direction;
export const selectScale = state => state.settings.scale;
export default settingsSlice.reducer;

import React, {
  useState,
  useEffect
} from 'react'
import {
  Auth
} from 'aws-amplify';
import {
  Hub
} from '@aws-amplify/core';
import Authenticator from './Authenticator'
const UI_AUTH_CHANNEL = 'UI Auth';
const AUTH_STATE_CHANGE_EVENT = 'AuthStateChange';
const AUTH_SIGNED_IN = 'signedin';
const AUTH_SIGNED_OUT = 'signedout'


export default function withAuthenticator(Component) {
  const AppWithAuthenticator = () => {
    const [signedIn, setSignedIn] = useState(false);

    useEffect(() => {
      async function authenticate() {
        try {
          await Auth.currentAuthenticatedUser()
          setSignedIn(true)
        } catch (error) {
          setSignedIn(false);
        }
      }

      function listenForUpdates() {
        Hub.listen(UI_AUTH_CHANNEL, (data) => {
          const {
            payload
          } = data

          if (AUTH_STATE_CHANGE_EVENT === payload.event) {
            if (payload.message === AUTH_SIGNED_IN) {
              setSignedIn(true)
            }

            if (payload.message === AUTH_SIGNED_OUT) {
              setSignedIn(false);
            }
          }
        });
      }
      authenticate()
      listenForUpdates()
    }, [])

    if (!signedIn) {
      return <Authenticator />;
    }

    return <Component />;
  }

  return AppWithAuthenticator
}

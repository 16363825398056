import React from 'react';
import { Diagram } from './features/diagram/Diagram';
import { Calendar } from './features/calendar/Calendar';
import { Legend } from './features/legend/Legend';
import { Settings } from './features/settings/Settings';
import { Logo } from './features/logo/Logo';
import './App.css';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import withAuthenticator from './features/auth/withAuthenticator'
require('dotenv').config()

function App() {
  return (
    <div className="App">
        <AmplifySignOut />
        <Diagram />
        <Settings />
        <Legend />
        <Calendar />
        <Logo />
    </div>
  );
}

export default withAuthenticator(App);
